.col-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom:30px;
    margin-top:30px;
}
.col {
    width: 33%;
    padding:20px;
}

.crimes {
    display: flex;
    flex-direction: column;
}

.crimes button {
    background: #b7e6ff;
    border: 0;
    padding: 16px;
    margin-bottom: 16px;
    color: var(--purple);
    border-radius: 8px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}
.crimes button:hover {
    background: rgba(246, 221, 233, 0.44);
}
.calc img {
    width: 100%;
}

.bold {
    font-weight: 700;

}

.nurse-img {
    display: flex;
    justify-content: center;
    align-items: center;
}
@media screen and (max-width: 640px) {

 .col {
     width:100%;
 }
.col.calc {
    order: -1;
    display: flex;
}
    .calc img {
        max-width: 75%;
        margin: auto;
    }
}
